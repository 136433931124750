// @flow
import { Box } from '@material-ui/core';
import React from 'react';
import IntroBlock from '../shared/IntroBlock';

const Intro = () => (
  <div id="intro">
    <IntroBlock
      title="導入のポイント"
      subTitle="クレカのいらないノーショー対策"
      text="予約サイトでの決済方法がクレカのみだとにノーショー対策はできますが、非クレカ層ユーザーには不便をかけてしまいます。クレカのいらないPaidyならノーショー対策とユーザビリティの向上を同時にできます。"
    />

    <Box mt="-130px">
      <IntroBlock
        subTitle="予約システムへの導入もスムーズ"
        text="予約システムの改修は悩ましいポイントかもしれませんが。Paidyならスムーズな導入で大規模な改修も必要ありません。"
      />
    </Box>

    <Box mt="-130px">
      <IntroBlock
        subTitle="航空券の入金率改善に"
        text="入金期限が決まっている航空券の場合、コンビニ決済では入金遅れで予約が取り消されることもしばしば。Paidyならコンビニに行く必要がないので入金率の改善が可能です。"
      />
    </Box>
  </div>
);

export default Intro;
